import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import MetaData from "../components/meta"
import { Helmet } from "react-helmet"
import PostCard from "../components/PostCard"

// Import styles
import "../styles/author.scss"

const Author = ({ data, location }) => {
  const author = data.ghostAuthor
  const posts = data.allGhostPost.edges
  const twitterUrl = author.twitter
    ? `https://twitter.com/${author.twitter.replace(/^@/, ``)}`
    : null
  const facebookUrl = author.facebook
    ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}`
    : null

  return (
    <Layout>
      <MetaData data={data} location={location} type="profile" />
      <Helmet>
        <meta name="robots" content="noindex, follow" />
      </Helmet>

      {/* Header */}
      <div
        className={
          "author-header" + (author.cover_image ? " with-cover-image" : "")
        }
        style={{
          backgroundImage: author.cover_image
            ? `url(${author.cover_image})`
            : `none`,
        }}
      >
        <div className="overlay">
          <div className="wrapper-1200">
            {author.profile_image && (
              <figure
                className="author-avatar"
                style={{
                  backgroundImage: `url(${author.profile_image})`,
                }}
              ></figure>
            )}

            <h1 className="author-name">{author.name}</h1>

            <p className="author-bio">{author.bio}</p>

            <div className="author-links">
              <div className="author-social-media">
                {twitterUrl && (
                  <a href={twitterUrl} target="_blank" rel="noopener">
                    <span className="link-svg">
                      <svg
                        aria-labelledby="simpleicons-twitter-icon"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title id="simpleicons-twitter-icon">
                          Twitter icon
                        </title>
                        <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                      </svg>
                    </span>
                  </a>
                )}

                {facebookUrl && (
                  <a href={facebookUrl} target="_blank" rel="noopener">
                    <span className="link-svg">
                      <svg
                        aria-labelledby="simpleicons-facebook-icon"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title id="simpleicons-facebook-icon">
                          Facebook icon
                        </title>
                        <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
                      </svg>
                    </span>
                  </a>
                )}
              </div>

              {author.location && (
                <div className="author-location">
                  <div className="author-links__separator">/</div>

                  <div className="author-location__main">
                    <span className="svg-container">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-map"
                      >
                        <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                        <line x1="8" y1="2" x2="8" y2="18"></line>
                        <line x1="16" y1="6" x2="16" y2="22"></line>
                      </svg>
                    </span>
                    <span className="text">{author.location}</span>
                  </div>
                </div>
              )}

              {author.website && (
                <div className="author-website">
                  <div className="author-links__separator">/</div>

                  <div className="author-website__main">
                    <span className="svg-container">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-link"
                      >
                        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                      </svg>
                    </span>
                    <span className="text">
                      <a
                        className=""
                        href={author.website}
                        target="_blank"
                        rel="noopener"
                      >
                        {author.website}
                      </a>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Posts */}
      <div className="author-posts">
        <div className="wrapper-1200">
          <div className="post-feed">
            {posts.map(({ node }) => (
              <PostCard key={node.id} post={node} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Author

export const pageQuery = graphql`
  query GhostAuthorQuery($slug: String!) {
    ghostAuthor(slug: { eq: $slug }) {
      ...GhostAuthorFields
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: {
        authors: { elemMatch: { slug: { eq: $slug } } }
        primary_tag: { slug: { eq: "blog" } }
      }
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`
